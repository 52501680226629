
body {
  font-family: Roboto, sans-serif !important;
}

.header {
  background-color: black;

  h1 {
    float: right;
    color: white;
    font-size: 50px;
  }

}

.main-wrapper {
  max-width: 550px;
  padding: 25px;
  margin: 40px auto 20px;

  .welcome-text {
    text-align: center;
    max-width: 220px;
    margin: auto;
    font-weight: bold;
  }
  .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }

  .comments-area{
    min-height: 100px;
  }

  .bootstrap-datetimepicker-widget{
    border: 1px solid #d4dadf;
    border-radius: 5px;
    padding: 15px 10px 0 10px;
    max-width: 300px;
    margin: auto;

    span.fa.fa-chevron-up, span.fa.fa-chevron-down {
      color: white;
    }

  }
  .SingleDatePicker, .SingleDatePickerInput{
    display: block;
    .DateInput{
      display: block;
      width: initial;
      border-radius: .25rem;
    }
    input {
      font-size: 1rem;
      padding: .375rem .75rem;
      display: block;
      border-radius: .25rem;
    }
  }
  .SingleDatePickerInput__withBorder{
    border: 1px solid #ced4da;
    border-radius: .25rem;
  }

  .DayPicker{
    margin: auto;
    z-index: 0;

    /* Fix for when the page is loaded in a hidden iframe */
    &.DayPicker__hidden{
      visibility: visible;
    }
    .DayPicker_transitionContainer{
      min-height: 295px;
    }
    /* End fix */

    &, .CalendarMonthGrid, .CalendarMonth{
      background-color: #F5F5F5;
    }
    .CalendarMonth_caption{
      color: #837E7E;
    }

    .CalendarDay {
      border: 1px solid #F4F4F4;
      color: #dedede;
      background: #8F929B;

      &:hover{
        background: #e4e7e7;
        border: 1px solid #e4e7e7;
        color: #252525;
      }

    }

    .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
      background: #4D5656;
      border: 1px double #6E7892;
      color: #e4e7e7;
    }

    .CalendarDay__blocked_out_of_range, .CalendarDay__blocked_out_of_range:active, .CalendarDay__blocked_out_of_range:hover {
      background: #E5E7E9;
      border: 1px solid #4f4f4f;
      color: #515151;
    }

    .DayPickerNavigation_button__default {
      background-color: #ADAEB1;
      border-color: #bcbcbc;

      .DayPickerNavigation_svg__horizontal{
        fill: #5E5F60;
      }
    }

  }
}

.datepicker{
  margin-bottom: 10px;
}

.TimePicker{
  margin: 30px;
  text-align: center;
  button{
    border: 2px solid #252525;
    background:none;
    display: inline-block;
    width: 35px;
    height: 35px;
    margin: 4px 8px;
    font-size: 26px;
    color: #252525;
    border-radius: 50px;
    line-height: 0;
  }
  .time-display{
    font-size: 40px;
  }

}

.footer {

  margin-bottom: 45px;

  p {
    margin: 0;
    text-align: center;
  }
}

.thank-you, .error-wrapper, .thanks-payment {

  text-align: center;

  .social-header {
    margin-top: 50px;
  }

  .social-icon {
    max-width: 100px;
    margin: 10px;
  }
  .nav button{
    width: 100%;
    border-color: #ffffff;
    background-color: #f3f3f3;
  }
}

.link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

#modal-iframe {
  min-height: 75vh;
  width: 100%;
}

.payment {
  img {
    width: 233px;
    margin-bottom: 15px;
  }
}

.panel-title {display: inline;font-weight: bold;}
.checkbox.pull-right { margin: 0; }
.pl-ziro { padding-left: 0px; }

a:hover{
 color: inherit;
  cursor: pointer;
}

a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
  &.active {
    color: white;
    text-decoration: none;
  }
}

#paypal-payment-link{
  border: none;
  width: 300px;
  display: inline-block;
  img{
    width: 100% ;
  }
}

